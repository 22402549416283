<div class="bottom-container">
  <div class="bottom-container-text">
    {{ "pay-full-amount-now" | translate }}
  </div>
  <img
    loading="lazy"
    src="../../../assets/images/phone.png"
    class="bottom-container-img"
    alt="phone"
  />
  <div class="bottom-container-text-2">
    هل انت متاكد لي تلقي مكالمه تأكيد الدفع
  </div>
  <div class="bottom-container-input">
    <div>تم ارجاء مكالمه هاتف على 201001003377</div>
  </div>
  <button class="approve-button" (click)="confirm()">نعم</button>
  <button
    class="decline-button"
    [class.disabled]="isRequestPending"
    (click)="close()"
  >
    ليس الآن
  </button>
</div>
