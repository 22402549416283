<button
  type="button"
  class="btn btn-text cancel-order-button"
  data-bs-toggle="modal"
  data-bs-target="#cancelOrderModal"
  [ngClass]="{ 'login-page-icon': isLogin }"
>
  <img
    [src]="
      isLogin
        ? '../../../../assets/images/cancel-order-modal/tag-cross-home.svg'
        : '../../../../assets/images/cancel-order-modal/tag-cross.svg'
    "
    class="img-fluid cancel-order"
    alt="cancel order"
  />
</button>

<div
  class="modal fade cancel-order-modal"
  id="cancelOrderModal"
  tabindex="-1"
  aria-labelledby="cancelOrderModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <img
          src="../../../../assets/images/cancel-order-modal/incomplete-order-warning.svg"
          class="img-fluid cancel-order"
          alt="incomplete order warning"
        />

        <p class="title">{{ "Order incomplete" | translate }}</p>
        <p class="subtitle">
          {{ "Are you sure you wan to cancel this order?" | translate }}
        </p>

        <button
          type="button"
          class="btn btn-primary continue-ordering-btn col-12"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          {{ "Continue Ordering" | translate }}

          <img
            src="../../../assets/images/login/side-arrow.svg"
            class="img-fluid ms-2"
            alt="arrow"
          />
        </button>

        <button
          type="button"
          class="btn btn-primary cancel-ordering-btn col-12"
          (click)="cancelOrder()"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          {{ "Cancel Order" | translate }}

          <img
            src="../../../assets/images/cancel-order-modal/cancel-incomplete-order.svg"
            class="img-fluid ms-2"
            alt="cancel order"
          />
        </button>
      </div>
    </div>
  </div>
</div>
