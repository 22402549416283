import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { HelperService } from 'src/app/services/Helper.service';
import { ErrorPopupService } from 'src/app/services/error-popup.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  @Input({ required: true }) orderDetailsRes?: OrderDetailsRes;
  title?: string = '';
  message?: string = '';

  isModalOpened: boolean = false;

  constructor(
    private translate: TranslateService,
    private helperService: HelperService,
    private errorPopupService: ErrorPopupService
  ) {}

  ngOnInit(): void {
    this.subscribeToErrorService();
  }

  subscribeToErrorService() {
    this.errorPopupService.errorData.subscribe((errorData) => {
      this.isModalOpened = errorData.isOpened;
      this.title = errorData.title;
      this.message = errorData.message;

      if (errorData.isOpened) {
        this.onErrorHandler();
      }
    });
  }

  handleErrorCallback() {
    window?.parent?.postMessage({ callback: 'errorCallback', data: null }, '*');
  }

  onErrorHandler() {
    this.handleErrorCallback();
    this.helperService.HandleErrorPaymentResult(this.orderDetailsRes);
  }

  tryAgain() {
    this.handleErrorCallback();

    if (this.orderDetailsRes?.data?.merchantUrls?.failure) {
      this.helperService.DirectRedirectToLink(
        this.orderDetailsRes?.data?.merchantUrls?.failure
      );
    }
  }
}
