<div class="row">
  <div class="col-12 applePayBtnContainer">
    <div class="details-container">
      <img
        src="../../../assets/images/apple-pay-intersect-1.svg"
        alt="apple pay intersect"
        class="apple-pay-intersect-1"
      />
      <img
        src="../../../assets/images/apple-pay-intersect-2.svg"
        alt="apple pay intersect"
        class="apple-pay-intersect-2"
      />

      <img
        src="../../../assets/images/shopping-bag-blue.svg"
        alt="cart"
        class="cart-icon"
      />

      <p class="total">{{ "total-amount" | translate }}</p>

      <p class="total-amount">{{ paidAmountNow }} {{ "Riyal" | translate }}</p>
    </div>

    <p class="continue-paying-with-apple-pay">
      {{ "continue-paying-with-apple-pay" | translate }}
    </p>

    <button
      id="applePayBtn"
      class="applePayBtn"
      (click)="handleApplePaySession()"
    >
      <img
        src="../../../assets/images/apple-pay-btn.svg"
        alt="Apple Pay"
        class="img-fluid"
      />
    </button>
  </div>

  <div class="col-12">
    <app-loader *ngIf="loading"></app-loader>
  </div>
</div>
