import {
  Component,
  DestroyRef,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { CheckoutService } from 'src/app/services/checkout.service';
import { PaymentService } from 'src/app/services/payment.service';
import { PaymentData, PaymentOptionType } from 'src/app/enums/PaymentType';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentProcessService } from 'src/app/services/payment-process.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-payment-options',
  standalone: false,
  templateUrl: './payment-options.component.html',
  styleUrl: './payment-options.component.scss',
})
export class PaymentOptionsComponent {
  @ViewChild('accordionElement', { static: false })
  accordionElement: ElementRef | undefined;

  orderDetailsRes: OrderDetailsRes | undefined;
  selectedPaymentOption: any;
  selectedPaymentOptionId: number = 0;
  noOfInstallments: any;
  cashBackEnable: boolean = false;
  paymentOptionData: any;
  paymentOptionTitle: string = '';
  paidAmountWithoutCashBack = 0;

  paymentOptionRes: any;
  balance: string = '0';
  paymentOption: any;
  totalAmount: number = 0;
  orderId: number = 0;
  loading: boolean = false;
  payNowAmount: number = 0;
  paydayOption: any;
  selectedPaydayOption: any;
  PaymentOptionType = PaymentOptionType;
  selectedPaymentOptionType: number = 0;
  currentLang = localStorage?.getItem('lang');

  paymentOptionsCalled = false;
  expandedPaymentOption = 0;

  destroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private checkoutService: CheckoutService,
    private paymentProcessService: PaymentProcessService
  ) {}

  get isCashBackEnabled() {
    return Number.parseFloat(this.balance) > 0;
  }

  ngOnInit(): void {
    this.loading = true;

    this.subscribeOrderDetails();
    this.subscribePaymentData();

    this.balance = localStorage.getItem('walletAmount') ?? '0';
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((orderDetailsRes: OrderDetailsRes) => {
        this.orderDetailsRes = orderDetailsRes;
        this.totalAmount = orderDetailsRes?.data.totalAmount;
        this.orderId = orderDetailsRes?.data.orderId ?? 0;

        if (this.orderId && !this.paymentOptionsCalled) {
          this.paymentOptionsCalled = true;
          this.getPaymentOptions();
        }
      });
  }

  subscribePaymentData() {
    this.paymentProcessService.paymentData
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((paymentData: PaymentData) => {
        this.paidAmountWithoutCashBack = paymentData.paidAmountWithoutCashBack;
      });
  }

  getPaymentOptions() {
    this.paymentService
      .PaymentOptionNewStructure(this.orderId)
      .subscribe((res: any) => {
        this.paymentOptionRes = res.data?.paymentOptionGroup;
        this.paymentOptionData = res.data;

        if (this.paymentOptionRes.length > 0) {
          this.setDefaultPaydayOption();

          setTimeout(() => {
            this.toggleCollapse(this.paymentOptionRes[0].paymentOptionType);
          }, 200);
        }

        this.loading = false;
      });
  }

  toggleCollapse(paymentOptionType: number) {
    const elementId = `flush-collapse-${paymentOptionType}`;
    const element = document.getElementById(elementId);

    if (element) {
      const collapse = new bootstrap.Collapse(element);
      if (paymentOptionType === this.expandedPaymentOption) {
        collapse.hide();
        this.expandedPaymentOption = 0;
      } else {
        this.expandedPaymentOption = paymentOptionType;
        collapse.show();
      }
    }
  }

  handleOpenCollapse(item: any) {
    const paymentOptionType = item.paymentOptionType;

    if (
      paymentOptionType === this.PaymentOptionType.PayInFull ||
      paymentOptionType === this.PaymentOptionType.PayDay
    ) {
      this.openCollapse(item);
    }

    this.toggleCollapse(paymentOptionType);
  }

  onChangeOptionId(e: any, paymentOptionTitle: string) {
    this.selectedPaymentOption = e;
    this.selectedPaymentOptionId = e.optionId;
    this.selectedPaymentOptionType = e.paymentOptionType;
    this.noOfInstallments = e.noOfInstallments;
    this.paymentOptionTitle = paymentOptionTitle;

    if (this.cashBackEnable) {
      this.payNowAmount = e.paidAmountNowAfterWallet;
    } else {
      this.payNowAmount = e.paidAmountNow;
    }

    this.paymentProcessService.calculatePaidAmount(
      this.cashBackEnable,
      this.selectedPaymentOption
    );
  }

  setDefaultPaydayOption() {
    this.paydayOption = this.paymentOptionRes.find((option: any) => {
      return option.paymentOptionType == this.PaymentOptionType.PayDay;
    });
    if (this.paydayOption) {
      this.selectedPaydayOption = this.paydayOption.options[0];
    }
  }

  previousPayDayOption(item: any, paymentOptionTitle: string) {
    for (let i = 0; i < this.paydayOption.options.length; i++) {
      if (this.paydayOption.options[i] == item) {
        this.setPaydayOption(i - 1, paymentOptionTitle);
      }
    }
  }

  nextPayDayOption(item: any, paymentOptionTitle: string) {
    for (let i = 0; i < this.paydayOption.options.length; i++) {
      if (this.paydayOption.options[i] == item) {
        this.setPaydayOption(i + 1, paymentOptionTitle);
      }
    }
  }

  openCollapse(item: any) {
    this.setDefaultPaydayOption();

    this.noOfInstallments = item.options[0].noOfInstallments;
    this.selectedPaymentOption = item.options[0];
    this.paymentOptionTitle = item.title;

    this.selectedPaymentOptionId = item.options[0].optionId;
    this.selectedPaymentOptionType = item.paymentOptionType;

    if (this.cashBackEnable) {
      this.payNowAmount = item.options[0].paidAmountNowAfterWallet;
    } else {
      this.payNowAmount = item.options[0].paidAmountNow;
    }

    this.paymentProcessService.calculatePaidAmount(
      this.cashBackEnable,
      this.selectedPaymentOption
    );
  }

  setPaydayOption(i: number, paymentOptionTitle: string) {
    if (i < this.paydayOption.options.length && i >= 0) {
      this.selectedPaydayOption = this.paydayOption.options[i];
      this.onChangeOptionId(this.paydayOption.options[i], paymentOptionTitle);
    }
  }

  checkCashBackValue(event: any) {
    this.cashBackEnable = event.target.checked;

    if (this.selectedPaymentOption) {
      if (this.cashBackEnable) {
        this.payNowAmount = this.selectedPaymentOption.paidAmountNowAfterWallet;
      } else {
        this.payNowAmount = this.selectedPaymentOption.paidAmountNow;
      }

      this.paymentProcessService.calculatePaidAmount(
        this.cashBackEnable,
        this.selectedPaymentOption
      );
    }
  }

  openPaymentMethods() {
    this.setPaymentData();

    this.router.navigate(['/payment-methods'], {
      queryParams: { ...this.route.snapshot.queryParams },
    });
  }

  setPaymentData() {
    this.paymentProcessService.savePaymentOptionsData(
      this.orderDetailsRes?.data?.orderId!,
      this.orderDetailsRes?.data?.guestOrderData?.customerName!,
      this.selectedPaymentOptionId,
      this.selectedPaymentOption,
      this.noOfInstallments,
      this.cashBackEnable,
      this.paymentOptionData.referralCode,
      this.paymentOptionTitle,
      this.selectedPaymentOption.paidAmountNow
    );
  }
}
