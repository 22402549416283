import {
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { OtpTransactionConfirmationService } from './service/otp-transaction-confirmation.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { PaymentProcessService } from 'src/app/services/payment-process.service';
import { PaymentService } from 'src/app/services/payment.service';
import { PaymentData } from 'src/app/enums/PaymentType';
import { interval, Subject, take, takeUntil } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-otp-transaction-confirmation',
  templateUrl: './otp-transaction-confirmation.component.html',
  styleUrls: ['./otp-transaction-confirmation.component.scss'],
})
export class OtpTransactionConfirmationComponent implements OnInit, OnDestroy {
  orderDetailsRes: OrderDetailsRes | undefined;
  invalidOTPCode: string = '';
  phoneNumber: string = '';

  otpForm: FormGroup;
  countdown: number = 60;
  idGuid: string = '';
  otpError: string = '';
  otpErrorFlag: boolean = false;

  enableSubmitBtn: boolean = true;
  showSubmitMsg: boolean = true;
  showVerificationError: boolean = false;
  isRequestPending = false;

  private destroy$ = new Subject<void>();
  destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private otpTransactionService: OtpTransactionConfirmationService,
    private checkoutService: CheckoutService,
    private paymentService: PaymentService,
    private paymentProcessService: PaymentProcessService
  ) {
    this.otpForm = this.fb.group({
      otp1: ['', [Validators.required, this.numericValidator()]],
      otp2: ['', [Validators.required, this.numericValidator()]],
      otp3: ['', [Validators.required, this.numericValidator()]],
      otp4: ['', [Validators.required, this.numericValidator()]],
    });

    this.phoneNumber = localStorage.getItem('mobile') ?? '';
  }

  ngOnInit() {
    this.subscribeOrderDetails();
    this.subscribePaymentData();
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(take(1))
      .subscribe((orderDetailsRes: OrderDetailsRes) => {
        this.requestOTP(orderDetailsRes?.data?.orderId);

        this.orderDetailsRes = orderDetailsRes;
      });
  }

  subscribePaymentData() {
    this.paymentProcessService.paymentData
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((paymentData: PaymentData) => {
        this.invalidOTPCode = paymentData.invalidOTPCode;

        if (paymentData.invalidOTPCode) {
          this.invalidateOTPForm();
        }
      });
  }

  invalidateOTPForm() {
    this.otpForm.setErrors({ incorrectOTP: true });

    this.enableSubmitBtn = false;
    this.showSubmitMsg = false;
    this.showVerificationError = true;
    this.isRequestPending = false;
    this.otpErrorFlag = false;
  }

  resendOTP() {
    this.showVerificationError = false;
    this.requestOTP();
  }

  requestOTP(orderId: number = this.orderDetailsRes?.data?.orderId ?? 0) {
    this.isRequestPending = true;
    this.otpForm.reset();
    this.enableSubmitBtn = false;

    this.otpErrorFlag = false;

    this.otpTransactionService.requestOTPForTransaction(orderId).subscribe({
      next: (res: any) => {
        res = JSON.parse(res);
        if (res.data.succeeded) {
          this.idGuid = res.data.idGuid;
          this.showSubmitMsg = true;
          this.countdown = 60;

          this.startTimer();
        } else {
          this.otpErrorFlag = true;
          this.otpError = res.data.error;
        }
      },
      complete: () => {
        this.isRequestPending = false;
      },
    });
  }

  private startTimer() {
    this.destroyOTPCounter();

    interval(1000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.showVerificationError = false;
          this.destroy$.next();
        }
      });
  }

  handleEnterPress() {
    if (!this.isRequestPending && this.otpForm.valid) {
      this.checkOTP();
    }
  }

  checkOTP() {
    this.isRequestPending = true;
    const userOTP: string =
      this.otpForm.controls['otp1'].value.toString() +
      this.otpForm.controls['otp2'].value.toString() +
      this.otpForm.controls['otp3'].value.toString() +
      this.otpForm.controls['otp4'].value.toString();

    this.paymentProcessService.saveOTP(userOTP, this.idGuid);
    this.paymentProcessService.startPaymentOperation();
  }

  moveFocus(
    value: any,
    nextInput: string,
    previousInput: string,
    currentInput: string
  ) {
    const inputValue = value.target.value;
    const inputNumber = Number(inputValue);

    if (value.key === 'Backspace') {
      this.otpForm.get(currentInput)?.patchValue(null);
      const element = document.getElementById(previousInput);
      if (element) {
        element.focus();
      }
    } else if (inputValue && inputNumber >= 0 && inputNumber <= 9) {
      const element = document.getElementById(nextInput);
      if (element) {
        element.focus();
      }
    }
    this.enableSubmitBtn = true;

    this.otpForm.setErrors(null);
  }

  numericValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === null || value === undefined || value === '') {
        return null; // Let required validator handle empty values
      }

      const sanitizedValue = value.replace(/\D/g, '');
      if (value !== sanitizedValue) {
        control.setValue(sanitizedValue);
      }

      return null;
    };
  }

  destroyOTPCounter() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnDestroy(): void {
    this.destroyOTPCounter();
  }
}
