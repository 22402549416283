import { Injectable } from '@angular/core';
import { HttpHelperService } from './base/http-helper.services';
import { TokenInitReq, TokenInitRes } from '../models/token-init';
import { SignInReq, SignInRes } from '../models/siginIn';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  haveActiveSession$ = new BehaviorSubject<boolean>(false);

  constructor(private httpHelperService: HttpHelperService) {}

  setHaveActiveSession(value: boolean = false) {
    this.haveActiveSession$.next(value);
  }

  public TokenInit(req: TokenInitReq) {
    return this.httpHelperService.SendPostRequest<TokenInitRes>(
      'user/token/init',
      req
    );
  }
  public SignIn(req: SignInReq) {
    return this.httpHelperService.SendPostRequest<SignInRes>(
      'user/sign-in',
      req
    );
  }
}
