<div class="container">
  <div class="row">
    <div class="md-12">
      <div class="logo">
        <img
          src="../../../assets/images/ApprovedFinal.svg"
          class="img-fluid"
          alt="Madfu"
        />
      </div>

      <p class="congratulating">
        {{
          "welcome-congratulations"
            | translate : { customerName: customerName || "" }
        }}
      </p>

      <div *ngIf="cashbackAmount > 0" class="cashback-amount">
        <div class="cashback-amount-container">
          <img
            src="../../../assets/images/sack-dollar.svg"
            class="img-fluid me-1"
            alt="Cashback"
          />
          <p>
            {{
              "cashback-with-amount"
                | translate : { amount: cashbackAmount || 0 }
            }}
          </p>
        </div>
      </div>

      <div class="order-details">
        <div *ngIf="paymentOptionTitle" class="item paid-amount">
          <p class="title">
            {{ "paid-amount" | translate }}
          </p>
          <p class="value">
            {{ paidAmountNow }} <span>{{ "Riyal" | translate }}</span>
          </p>
        </div>

        <div class="item">
          <p class="title">
            {{ "orderId" | translate }}
          </p>
          <p class="value">{{ orderReferenceNumber }}</p>
        </div>

        <div *ngIf="paymentOptionTitle" class="item">
          <p class="title">
            {{ "payment-option" | translate }}
          </p>
          <p class="value">{{ paymentOptionTitle }}</p>
        </div>
      </div>

      <div class="col-12">
        <div class="form-row align-items-center mt-5">
          <div class="col-auto">
            <button
              type="button"
              class="go-home-btn btn btn-primary col-12"
              (click)="RedirectToMerchantSuccessLink()"
            >
              {{ "back-to-home" | translate }}

              <img
                src="../../../assets/images/login/side-arrow.svg"
                class="img-fluid ms-2"
                alt="arrow"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
