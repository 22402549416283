import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorPopupService {
  errorData = new BehaviorSubject({
    isOpened: false,
    title: '',
    message: '',
  });

  constructor() {}

  openModal(title: string, message: string) {
    this.errorData.next({
      isOpened: true,
      title: title,
      message: message,
    });
  }
}
