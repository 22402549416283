<div class="row">
  <div class="col-12">
    <iframe
      title="visa payment"
      id="threeDsIFrame"
      [src]="sanitizedThreeDSUrl"
      name="threeDsIFrame"
      #threeDsIFrame
      sandbox="allow-scripts allow-same-origin allow-forms"
    ></iframe>
  </div>
</div>
