import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationLanguages } from 'src/app/enums/lang';
import { LoaderService } from '../Loader.Service';

@Injectable({
  providedIn: 'root',
})
export class ChangeLanguageService {
  applicationLang = ApplicationLanguages;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private loaderService: LoaderService
  ) {}

  changeLanguage(lang?: string) {
    this.loaderService.show();

    const newLang = lang ?? this.getNewLanguage();

    localStorage.setItem('lang', newLang);

    this.setAppLanguage(newLang);
    this.setHTMLDirectionAndLanguage(newLang);
    this.setNewStyleFile(newLang);

    setTimeout(() => {
      this.loaderService.hide();
    }, 300);
  }

  setHTMLDirectionAndLanguage(lang: string) {
    let direction = 'ltr';
    if (lang == this.applicationLang.Ar) {
      direction = 'rtl';
    }

    let htmlTag = this.document.getElementsByTagName('html')[0];

    htmlTag.dir = direction;
    htmlTag.lang = lang;

    const body = document.querySelector('body')!;
    body.className = direction;
    body.dir = direction;
  }

  setAppLanguage(lang: string) {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  setNewStyleFile(lang: string) {
    const styleLink = this.document.getElementById(
      'langCss'
    ) as HTMLLinkElement;

    const stylePath = this.getNewStyleFilePath(lang);
    const currentFilePathName = styleLink.href.split('/').pop();

    if (currentFilePathName !== stylePath.split('/').pop()) {
      styleLink.href = stylePath;
    }
  }

  getNewStyleFilePath(lang: string) {
    // when update bootstrap version update files in the path
    const arStyleFileName = 'bootstrap.rtl.min.css';
    const enStyleFileName = 'bootstrap.min.css';

    const styleFileName =
      lang === this.applicationLang.Ar ? arStyleFileName : enStyleFileName;

    return (
      'https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.2.3/css/' +
      styleFileName
    );
  }

  getNewLanguage() {
    return this.translate.currentLang == this.applicationLang.Ar
      ? this.applicationLang.En
      : this.applicationLang.Ar;
  }

  getLanguage() {
    return this.translate.currentLang;
  }
}
