import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentData, PaymentType, statusCode } from '../enums/PaymentType';
import { PaymentTransactionRes } from '../models/paymentTransaction';
import { PaymentService } from './payment.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, take } from 'rxjs';
import { ErrorPopupService } from './error-popup.service';
import { PaymentOption } from '../models/paymentOption';
import { ExecuteTransactionAfterApplePayReq } from '../models/ExecuteTransactionAfterApplePay';
import { LoaderService } from './Loader.Service';

@Injectable({
  providedIn: 'root',
})
export class PaymentProcessService {
  paymentData = new BehaviorSubject<PaymentData>({
    orderId: undefined,
    customerName: '',
    selectedPaymentOptionId: 0,
    selectedPaymentOption: undefined,
    noOfInstallments: 0,
    cashBackEnable: false,
    fullCashBack: false,
    referralCode: undefined,
    paymentOptionTitle: '',
    invalidOTPCode: '',
    otp: '',
    idGuid: '',
    paymentPageLink: '',
    cashbackFromOrder: 0,
    paidAmountNow: 0,
    paidAmountWithoutCashBack: 0,
    amountFromCashBack: 0,
    isAllowApplePay: false,
    selectedPaymentType: PaymentType.Card,
    merchantReference: '',
    orderReferenceNumber: '',
  });

  isRequestPending: boolean = false;
  paymentType = PaymentType;
  statusCode = statusCode;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private translate: TranslateService,
    private errorPopupService: ErrorPopupService,
    private loaderService: LoaderService
  ) {}

  savePaymentOptionsData(
    orderId: number,
    customerName: string,
    selectedPaymentOptionId: number,
    selectedPaymentOption: PaymentOption,
    noOfInstallments: number,
    cashBackEnable: boolean,
    referralCode: number,
    paymentOptionTitle: string,
    paidAmountNow: number
  ) {
    this.paymentData.next({
      ...this.paymentData.value,
      orderId,
      customerName,
      selectedPaymentOptionId,
      selectedPaymentOption,
      noOfInstallments,
      cashBackEnable,
      referralCode,
      paymentOptionTitle,
      paidAmountNow,
    });
  }

  calculatePaidAmount(
    cashBackEnable: boolean,
    selectedPaymentOption: PaymentOption
  ) {
    const firstPayment = parseFloat(
      selectedPaymentOption?.paymentPlan[0].amount ?? '0'
    );

    let fullCashBack = false;
    let amountFromCashBack = 0;
    let paidAmountWithoutCashBack = firstPayment;

    if (cashBackEnable) {
      const storedWalletAmount = parseFloat(
        localStorage.getItem('walletAmount') ?? '0'
      );

      if (storedWalletAmount > 0) {
        if (firstPayment > storedWalletAmount) {
          paidAmountWithoutCashBack = firstPayment - storedWalletAmount;
          amountFromCashBack = storedWalletAmount;
        } else {
          paidAmountWithoutCashBack = 0;
          fullCashBack = true;
          amountFromCashBack = firstPayment;
        }
      }
    }

    this.paymentData.next({
      ...this.paymentData.value,
      fullCashBack,
      amountFromCashBack,
      paidAmountWithoutCashBack,
    });
  }

  saveOTP(otp: string, idGuid: string) {
    this.paymentData.next({
      ...this.paymentData.value,
      otp,
      idGuid,
    });
  }

  savePaymentPageLink(paymentPageLink: string) {
    this.paymentData.next({
      ...this.paymentData.value,
      paymentPageLink,
    });
  }

  saveCashbackFromOrder(cashbackFromOrder: number) {
    this.paymentData.next({
      ...this.paymentData.value,
      cashbackFromOrder,
    });
  }

  saveAmazonPaymentData(paidAmountNow: number, merchantReference: string) {
    this.paymentData.next({
      ...this.paymentData.value,
      paidAmountNow,
      merchantReference,
    });
  }

  saveIsApplePayAllowed(isAllowApplePay: boolean) {
    this.paymentData.next({
      ...this.paymentData.value,
      isAllowApplePay,
    });
  }

  saveSelectedPaymentType(selectedPaymentType: PaymentType) {
    this.paymentData.next({
      ...this.paymentData.value,
      selectedPaymentType,
    });
  }

  saveOrderReferenceNumber(orderReferenceNumber: string) {
    this.paymentData.next({
      ...this.paymentData.value,
      orderReferenceNumber,
    });
  }

  startPaymentOperation() {
    this.isRequestPending = true;

    this.paymentData.pipe(take(1)).subscribe((paymentData) => {
      if (paymentData.fullCashBack) {
        this.payAllAmountWithCashBack(paymentData);
      } else if (
        paymentData.selectedPaymentType === PaymentType.Card ||
        paymentData.selectedPaymentType === PaymentType.MADA
      ) {
        this.initiateCardPayment(paymentData);
      } else if (paymentData.selectedPaymentType === PaymentType.ApplePay) {
        this.confirmApplePay(paymentData);
      }
    });
  }

  payAllAmountWithCashBack(paymentData: PaymentData) {
    const orderId = paymentData.orderId ?? 0;

    this.paymentService
      .PaymentTransactionFullCashBack<PaymentTransactionRes>(
        paymentData.selectedPaymentOptionId,
        orderId,
        paymentData.otp,
        paymentData.idGuid,
        paymentData.amountFromCashBack
      )
      .subscribe({
        next: (res: any) => {
          try {
            JSON.parse(res);
            res = JSON.parse(res);
          } catch (e) {}

          if (res.data.statusCode == statusCode.Completed) {
            this.saveOrderReferenceNumber(res.data.orderReferenceNumber);
            this.openSuccessPaymentPage();
          } else {
            this.openErrorModal();
          }
        },
        error: (error) => {
          this.handleTransactionError(error, paymentData);
        },
      });
  }

  openSuccessPaymentPage() {
    this.router.navigate(['/payment-success'], {
      queryParams: this.route.snapshot.queryParams,
    });
  }

  handleTransactionError(error: any, paymentData: PaymentData) {
    if (error.error.status == statusCode.needYakeenVerification) {
      this.openConfirmPaymentUsingBirthDate();
    } else if (error.error.status == statusCode.CouldNotProcessOTP) {
      const errorMessage = paymentData.otp ? error.error.detail : '';
      this.openConfirmPaymentUsingOTP(errorMessage);
    } else {
      this.openErrorModal();
    }
  }

  openErrorModal(
    title: string = this.translate.instant('paying-problem-title'),
    message: string = this.translate.instant('paying-problem-subtitle')
  ) {
    this.errorPopupService.openModal(title, message);
  }

  openConfirmPaymentUsingBirthDate() {
    this.router.navigate(['/birth-date-verification'], {
      queryParams: this.route.snapshot.queryParams,
    });
  }

  openConfirmPaymentUsingOTP(invalidOTPCode: string = '') {
    this.updateInvalidOTPCode(invalidOTPCode);

    this.router.navigate(['/otp-verification'], {
      queryParams: this.route.snapshot.queryParams,
    });
  }

  updateInvalidOTPCode(invalidOTPCode: string) {
    const data: PaymentData = {
      ...this.paymentData.value,
      invalidOTPCode,
    };

    this.paymentData.next(data);
  }

  initiateCardPayment(paymentData: PaymentData) {
    const orderId = paymentData.orderId ?? 0;

    this.isRequestPending = true;
    this.paymentService
      .PaymentTransaction(
        paymentData.selectedPaymentOptionId,
        orderId,
        paymentData.otp,
        paymentData.idGuid,
        paymentData.amountFromCashBack,
        paymentData.selectedPaymentType
      )
      .subscribe({
        next: (res: any) => {
          try {
            JSON.parse(res);
            res = JSON.parse(res);
          } catch (e) {}

          this.saveOrderReferenceNumber(res.data.orderReferenceNumber);
          this.openThreeDSModal(res);
        },
        error: (error) => {
          this.handleTransactionError(error, paymentData);
        },
      });
  }

  openThreeDSModal(res: any) {
    this.savePaymentPageLink(res.data.paymentPageLink);

    this.router.navigate(['/card-payment'], {
      queryParams: this.route.snapshot.queryParams,
    });
  }

  confirmApplePay(paymentData: PaymentData) {
    const orderId = paymentData.orderId ?? 0;

    this.isRequestPending = true;
    this.paymentService
      .PaymentTransactionApplePay(
        paymentData.selectedPaymentOptionId,
        orderId,
        paymentData.otp,
        paymentData.idGuid,
        paymentData.amountFromCashBack
      )
      .subscribe({
        next: (res: any) => {
          try {
            JSON.parse(res);
            res = JSON.parse(res);
          } catch (e) {}

          this.saveOrderReferenceNumber(res.data.orderReferenceNumber);
          this.openApplePayPaymentPage(res, paymentData);
        },
        error: (error) => {
          this.handleTransactionError(error, paymentData);
        },
      });
  }

  openApplePayPaymentPage(res: any, paymentData: PaymentData): void {
    this.saveAmazonPaymentData(
      Number.parseFloat(res.data.amount),
      res.data.id || ''
    );

    this.router.navigate(['/apply-pay-payment'], {
      queryParams: this.route.snapshot.queryParams,
    });
  }
}
