import { Component, Input } from '@angular/core';
import { PaymentPlan } from 'src/app/models/paymentOption';

@Component({
  selector: 'app-payment-schedule',
  standalone: false,
  templateUrl: './payment-schedule.component.html',
  styleUrl: './payment-schedule.component.scss',
})
export class PaymentScheduleComponent {
  @Input({ required: true }) paymentPlan: PaymentPlan[] | undefined;
  @Input({ required: true }) cashBackEnable = false;
  @Input({ required: true }) paidAmountWithoutCashBack = 0;

  getCircleProgress(i: number) {
    const totalPayments = this.paymentPlan?.length ?? 0;

    return parseInt(((i / totalPayments) * 100).toString());
  }
}
