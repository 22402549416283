import { Component, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { SignInReq } from 'src/app/models/siginIn';
import { TokenInitReq } from 'src/app/models/token-init';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login-form',
  standalone: false,
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
})
export class FormComponent {
  @Input() orderDetailsRes: OrderDetailsRes | undefined;

  invalidMobileLen: boolean = false;
  loginForm: FormGroup;
  isRequestPending: boolean = false;
  msgError: string = '';
  deviceInfo: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder
  ) {
    this.loginForm = this.fb.group({
      mobile: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.setUserMobileNumberInFormData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['orderDetailsRes']) {
      this.setUserMobileNumberInFormData();
    }
  }

  setUserMobileNumberInFormData() {
    this.loginForm = this.fb.group({
      mobile: [
        this.orderDetailsRes?.data?.guestOrderData?.customerMobile
          ? this.orderDetailsRes?.data?.guestOrderData?.customerMobile.replace(
              /^0+/,
              ''
            )
          : '',
        [Validators.required, Validators.minLength(9), Validators.maxLength(9)],
      ],
    });
  }

  OnFocusInsideInput() {
    this.invalidMobileLen = false;
  }

  checkValidation() {
    const mobileControl = this.loginForm.get('mobile')!;

    if (mobileControl.hasError('incorrectMobile')) {
      mobileControl.setErrors(null);
    }

    if (mobileControl?.value?.length !== 9) {
      this.invalidMobileLen = true;
      mobileControl.setErrors({ invalidLength: true });
    } else {
      mobileControl.setErrors(null);
      this.invalidMobileLen = false;
    }
  }

  handleEnterPress() {
    if (!this.isRequestPending && this.loginForm.valid) {
      this.handleLogin();
    }
  }

  handleLogin() {
    this.isRequestPending = true;

    const tokenReq: TokenInitReq = {
      systemInfo: window.navigator.userAgent,
      uuid: uuidv4().replace(/-/g, ''),
    };

    this.authService.TokenInit(tokenReq).subscribe((res: any) => {
      localStorage.setItem('token', res.token);

      this.login();
    });
  }

  login() {
    const signinReq: SignInReq = {
      mobile: this.loginForm.value.mobile,
      otp: null,
    };

    localStorage.setItem('mobile', this.loginForm.value.mobile);

    this.authService.SignIn(signinReq).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.authService.setHaveActiveSession(res.haveActiveSession);
          this.openOTPVerificationModal();
        } else {
          this.msgError = res.message;
          this.loginForm.get('mobile')?.setErrors({ incorrectMobile: true });
        }

        this.isRequestPending = false;
      },
      error: (error) => {
        this.isRequestPending = false;
      },
    });
  }

  openOTPVerificationModal() {
    this.router.navigate(['/login-otp-verification'], {
      queryParams: {
        ...this.route.snapshot.queryParams,
      },
    });
  }
}
