import { Injectable } from '@angular/core';
import { HttpHelperService } from './base/http-helper.services';
import { ReplaySubject } from 'rxjs';
import { GetOrderDetailsReq, OrderDetailsRes } from '../models/getOrderDetails';

@Injectable({ providedIn: 'root' })
export class CheckoutService {
  orderDetails = new ReplaySubject<OrderDetailsRes>();

  constructor(private httpHelperService: HttpHelperService) {}

  public GetOrderDetails(req: GetOrderDetailsReq) {
    this.httpHelperService
      .SendPostRequest<OrderDetailsRes>('user/Checkout/GetOrderDetails', req)
      .subscribe((res: OrderDetailsRes) => {
        this.orderDetails.next(res);
        localStorage.setItem('orderDetailsRes', JSON.stringify(res));
      });
  }
}
