import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PaymentProcessService } from 'src/app/services/payment-process.service';
import { PaymentData } from 'src/app/enums/PaymentType';
import { take } from 'rxjs';

@Component({
  selector: 'app-three-ds-payment',
  templateUrl: './three-ds-payment.component.html',
  styleUrls: ['./three-ds-payment.component.scss'],
})
export class ThreeDsPaymentComponent implements OnInit {
  paymentPageLink: string = '';
  sanitizedThreeDSUrl: SafeUrl | undefined;
  isErrorModalOpened = false;

  constructor(
    private sanitizer: DomSanitizer,
    private paymentProcessService: PaymentProcessService
  ) {}

  ngOnInit(): void {
    this.subscribePaymentData();
  }

  subscribePaymentData() {
    this.paymentProcessService.paymentData
      .pipe(take(1))
      .subscribe((paymentData: PaymentData) => {
        this.paymentPageLink = paymentData.paymentPageLink;

        this.sanitizedThreeDSUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(
            paymentData.paymentPageLink
          );
      });
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent): void {
    // Access the data sent from the iframe
    const success = event?.data?.success;

    if (success === 'true') {
      this.paymentProcessService.saveCashbackFromOrder(
        Number(event?.data?.cashback) || 0
      );
      this.paymentProcessService.openSuccessPaymentPage();
    } else {
      if (!this.isErrorModalOpened) {
        this.isErrorModalOpened = true;
        this.paymentProcessService.openErrorModal();
      }
    }
  }
}
