import { Injectable } from '@angular/core';
import { HttpHelperService } from 'src/app/services/base/http-helper.services';

@Injectable({
  providedIn: 'root',
})
export class DateOfBirthService {
  constructor(private httpHelperService: HttpHelperService) {}

  setDateOfBirth(dateOfBirth: any) {
    return this.httpHelperService.SendGetRequest(
      `user/UserYakeenVerification?dateOfBirth=${
        dateOfBirth.year
      }-${dateOfBirth.month.toString().padStart(2, '0')}-${dateOfBirth.day
        .toString()
        .padStart(2, '0')}`
    );
  }
}
