import { Injectable } from '@angular/core';
import { HttpHelperService } from 'src/app/services/base/http-helper.services';

@Injectable({
  providedIn: 'root'
})
export class OtpTransactionConfirmationService {


  constructor(
    private httpHelperService:HttpHelperService,
    ) { }

  requestOTPForTransaction(orderId : number){
    const data = {
      orderId: orderId
    }
    return  this.httpHelperService.SendPostRequestAndReturnView(`api/user/PurchaseOtp/send` , data);
  }
}

