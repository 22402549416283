import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-payment-va-tel',
  templateUrl: './confirm-payment-va-tel.component.html',
  styleUrls: ['./confirm-payment-va-tel.component.css'],
})
export class ConfirmPaymentVaTelComponent {
  isRequestPending: boolean = false;

  constructor(private router: Router) {}

  close() {
    // TODO: this should close this page, it wasn't implemented in the original code
  }

  confirm() {
    this.isRequestPending = true;

    // TODO: this should open ConfirmPaymentUsingBirthDateComponent

    this.isRequestPending = false;
  }
}
