import { Component } from '@angular/core';

@Component({
  selector: 'app-qr-page',
  templateUrl: './qr-page.component.html',
  styleUrls: ['./qr-page.component.css'],
})
export class QrPageComponent {
  constructor() {}

  goPay() {
    // TODO: handle the logic
  }
}
