import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ChangeLanguageService } from 'src/app/services/helper/change-language.service';
import { ApplicationLanguages } from 'src/app/enums/lang';

@Component({
  selector: 'app-pages-layout',
  standalone: false,
  templateUrl: './pages-layout.component.html',
  styleUrl: './pages-layout.component.scss',
})
export class PagesLayoutComponent {
  orderDetailsRes: OrderDetailsRes | undefined;

  destroyRef = inject(DestroyRef);

  applicationLanguages = ApplicationLanguages;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private checkoutService: CheckoutService,
    protected changeLanguageService: ChangeLanguageService
  ) {}

  ngOnInit(): void {
    this.subscribeOrderDetails();
  }
  get isOrderSuccessPage() {
    return this.router.url.includes('/payment-success');
  }

  get isPaymentOptionsPage() {
    return this.router.url.includes('/payment-options');
  }

  get isPaymentMethodsPage() {
    return this.router.url.includes('/payment-methods');
  }

  get isPaymentPages() {
    return (
      this.router.url.includes('/payment-options') ||
      this.router.url.includes('/payment-methods')
    );
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: OrderDetailsRes) => {
        this.orderDetailsRes = res;
      });
  }

  changeLanguage() {
    const newLanguage = this.changeLanguageService.getNewLanguage();
    localStorage.setItem('lang', newLanguage);

    if (this.isPaymentOptionsPage) {
      this.refresh();
    } else {
      this.openPaymentOptionsPage();
    }
  }

  refresh() {
    window.location.reload();
  }

  openPaymentOptionsPage() {
    this.router
      .navigate(['/payment-options'], {
        queryParams: { ...this.route.snapshot.queryParams },
      })
      .then(() => {
        this.refresh();
      });
  }

  goBack() {
    if (this.isPaymentMethodsPage) {
      this.openPaymentOptionsPage();
    }
  }
}
