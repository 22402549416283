import { Component, Injectable } from '@angular/core';
import { DateOfBirthService } from './service/date-of-birth.service';
import { statusCode } from 'src/app/enums/PaymentType';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { CheckoutService } from 'src/app/services/checkout.service';
import { PaymentService } from 'src/app/services/payment.service';
import { PaymentProcessService } from 'src/app/services/payment-process.service';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : '';
  }
}

@Component({
  selector: 'app-confirm-payment-using-birth-date',
  templateUrl: './confirm-payment-using-birth-date.component.html',
  styleUrls: ['./confirm-payment-using-birth-date.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class ConfirmPaymentUsingBirthDateComponent {
  gregorianDate: NgbDateStruct | undefined;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;

  selectedDate: any = '';
  statusCode = statusCode;
  showDateOfBirthError: boolean = false;
  dateOfBirthErrorMsg: string = '';
  model: any = {};
  dateType: string = 'gregorian';

  constructor(
    private dateOfBirth: DateOfBirthService,
    private checkoutService: CheckoutService,
    private paymentService: PaymentService,
    private paymentProcessService: PaymentProcessService
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = { year: currentYear - 100, month: 1, day: 1 };
    this.maxDate = { year: currentYear - 18, month: 1, day: 1 };
  }

  onInputChange(e: any) {
    this.selectedDate = e;
  }

  confirm() {
    this.showDateOfBirthError = false;
    this.dateOfBirthErrorMsg = '';
    this.dateOfBirth.setDateOfBirth(this.selectedDate).subscribe((res: any) => {
      if (res.status) {
        this.paymentProcessService.startPaymentOperation();
      } else {
        this.showDateOfBirthError = true;
        this.dateOfBirthErrorMsg = res.message;
      }
    });
  }
}
