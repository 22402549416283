import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ChangeLanguageService } from 'src/app/services/helper/change-language.service';
import { environment } from 'src/app/environments/environment';
import { OsType } from 'src/app/enums/DeviceType';

@Component({
  selector: 'app-login',
  standalone: false,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  orderDetailsRes: OrderDetailsRes | undefined;

  destroyRef = inject(DestroyRef);

  constructor(
    private checkoutService: CheckoutService,
    protected changeLanguageService: ChangeLanguageService,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.subscribeOrderDetails();
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: OrderDetailsRes) => {
        this.orderDetailsRes = res;
      });
  }

  changeLanguage() {
    this.changeLanguageService.changeLanguage();
  }

  openAppPage() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    let registerLink = environment.androidStoreLink;

    if (
      deviceInfo.os.toLocaleLowerCase() == OsType.MAC_OS ||
      deviceInfo.os.toLocaleLowerCase() == OsType.IOS_OS
    ) {
      registerLink = environment.iOSStoreLink;
    }

    window.open(registerLink, '_blank');
  }
}
