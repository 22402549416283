import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QrPageComponent } from './components/qr-page/qr-page.component';
import { QRCodeModule } from 'angularx-qrcode';
import { SuccessPaymentComponent } from './pages/success-payment/success-payment.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { HttpHelperService } from './services/base/http-helper.services';
import { TokenInterceptorService } from './interceptors/token.interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmPaymentVaTelComponent } from './components/confirm-payment-va-tel/confirm-payment-va-tel.component';
import { ThreeDsPaymentComponent } from './pages/three-ds-payment/three-ds-payment.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { LeadingZeroDirective } from './directives/leading-zero.directive';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { ApplePayComponent } from './pages/apple-pay/apple-pay.component';
import { CommonModule, DatePipe } from '@angular/common';
import { HijriDatePickerComponent } from './components/shared/hijri-date-picker/hijri-date-picker.component';
import { OtpTransactionConfirmationComponent } from './pages/otp-transaction-confirmation/otp-transaction-confirmation.component';
import { LoginComponent } from './pages/login/login.component';
import { FormComponent } from './components/login/form/form.component';
import { CancelOrderModalComponent } from './components/shared/cancel-order-modal/cancel-order-modal.component';
import { EmptyPageComponent } from './pages/empty-page/empty-page.component';
import { LoginOtpComponent } from './pages/login-otp/login-otp.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaymentOptionsComponent } from './pages/payment-options/payment-options.component';
import { PagesLayoutComponent } from './layout/pages-layout/pages-layout.component';
import { PaymentMethodsComponent } from './pages/payment-methods/payment-methods.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ConfirmPaymentUsingBirthDateComponent } from './pages/confirm-payment-using-birth-date/confirm-payment-using-birth-date.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { PaymentScheduleComponent } from './components/shared/payment-schedule/payment-schedule.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FormComponent,
    CancelOrderModalComponent,
    EmptyPageComponent,
    LoginOtpComponent,
    PagesLayoutComponent,
    PaymentOptionsComponent,
    PaymentMethodsComponent,
    SuccessPaymentComponent,
    ConfirmPaymentUsingBirthDateComponent,
    HijriDatePickerComponent,
    OtpTransactionConfirmationComponent,
    ThreeDsPaymentComponent,
    ApplePayComponent,
    LoaderComponent,
    ErrorPageComponent,
    PaymentScheduleComponent,

    QrPageComponent,
    ConfirmPaymentVaTelComponent,

    LeadingZeroDirective,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgbDatepickerModule,
    QRCodeModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RoundProgressModule,
  ],
  providers: [
    HttpHelperService,
    TranslateService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
