import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { HelperService } from 'src/app/services/Helper.service';
@Component({
  selector: 'app-cancel-order-modal',
  standalone: false,
  templateUrl: './cancel-order-modal.component.html',
  styleUrl: './cancel-order-modal.component.scss',
})
export class CancelOrderModalComponent {
  @Input() isLogin: boolean = false;
  @Input({ required: true }) orderDetailsRes?: OrderDetailsRes;

  @ViewChild('cancelOrderModal') cancelOrderModal?: ElementRef;
  isOpened = false;

  constructor(private helperService: HelperService) {}

  async cancelOrder() {
    if (window.parent) {
      window.parent.postMessage(
        { callback: 'cancelCallback', data: null },
        '*'
      );

      this.helperService.HandleCancelPaymentResult(this.orderDetailsRes);
    }
  }
}
