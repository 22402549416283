<div class="container">
  <div class="row">
    <div class="md-12">
      <div class="logo">
        <img
          src="../../../assets/images/shared/madfu-pages-logo.svg"
          class="img-fluid"
          alt="Madfu"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-10 offset-1">
      <div class="mb-10 info row">
        <span class="col-12 info-title">
          قم بمسح رمز الاستجابة السريعة او ادخال رقم الفاتورة لإكمال طلبك
        </span>
      </div>

      <div class="mt-4 row">
        <div class="qr-first-div"></div>
        <div class="qr-second-div"></div>

        <div class="qr-contain-div mr-2 ml-2 col-6 offset 3">
          <qrcode
            [qrdata]="'Your data string'"
            [width]="245"
            [errorCorrectionLevel]="'M'"
          ></qrcode>
        </div>
      </div>

      <div class="mb-10 info row">
        <span class="col-12 info-title invoice-number-text">
          رقم الفاتورة
        </span>

        <span
          class="col-12 info-title invoice-number"
          (click)="goPay()"
          (keydown.enter)="goPay()"
        >
          123-567-345-456
        </span>
      </div>

      <div class="form-text text-center mt-4">
        &nbsp;لا تمتلك حساب؟
        <a href="#" class="text-dark fw-bold"> إنشاء حساب</a>
      </div>
    </div>
  </div>
</div>
