import { Component, EventEmitter, Injectable, Output } from '@angular/core';
import {
  NgbCalendar,
  NgbCalendarIslamicCivil,
  NgbDateStruct,
  NgbDatepickerI18n,
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment-hijri';

const WEEKDAYS = ['ن', 'ث', 'ر', 'خ', 'ج', 'س', 'ح'];
const MONTHS = [
  'محرم',
  'صفر',
  'ربيع الأول',
  'ربيع الآخر',
  'جمادى الأولى',
  'جمادى الآخرة',
  'رجب',
  'شعبان',
  'رمضان',
  'شوال',
  'ذو القعدة',
  'ذو الحجة',
];
@Injectable()
export class IslamicI18n extends NgbDatepickerI18n {
  getMonthShortName(month: number) {
    return MONTHS[month - 1];
  }

  getMonthFullName(month: number) {
    return MONTHS[month - 1];
  }

  getWeekdayLabel(weekday: number) {
    return WEEKDAYS[weekday - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.year}/${date.month}/${date.day}`;
  }
}

@Component({
  selector: 'app-hijri-date-picker',
  templateUrl: './hijri-date-picker.component.html',
  styleUrls: ['./hijri-date-picker.component.scss'],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicCivil },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n },
  ],
})
export class HijriDatePickerComponent {
  @Output() selectedHijriDate: EventEmitter<any> = new EventEmitter<any>();

  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  hijriDate: NgbDateStruct | undefined;

  constructor() {
    this.minDate = { year: moment().iYear() - 100, month: 1, day: 1 };
    this.maxDate = { year: moment().iYear() - 18, month: 1, day: 1 };
  }

  onHijriInputChange(e: any) {
    this.selectedHijriDate.emit(this.hijriDate);
  }
}
